import { useEffect, useState } from 'react';
import { updateStorage, getStorageData } from '../../../../Common/StorageManager';
import Input from '../../../../Components/Input/Input';
import FileInput from '../../../../Components/Input/FileInput';
import { IMG_TYPES } from '../../../../Common/Constants';
import './Details.css';
import '../Shared.css';

const MAIN_FORM_KEYS = [
  'main.name',
  'main.phone',
  'main.car',
  'main.address',
  'main.email',
  'main.emergencyContact',
  'main.emergencyContactPhone',
];

const SECONDARY_FORM_KEYS = [
  'secondary.name',
  'secondary.phone',
  'secondary.address',
  'secondary.email',
  'secondary.emergencyContact',
  'secondary.emergencyContactPhone',
];

function Details({ disableNext = () => true, filesChanged = () => '' }) {
  const [form, setForm] = useState(getFormDataFromStorage());
  const [validForm, setValidForm] = useState(isFormValid());

  useEffect(() => disableNext(!validForm), [disableNext, validForm]);

  const updateForm = (k, value) => {
    const [group, key] = k.split('.');
    const data = { [group]: { ...form[group], [key]: value } };
    setForm({ ...form, ...data });
    updateStorage(data);
    setValidForm(isFormValid());
  }

  const loadFile = (file) => {
    if (file) {
      filesChanged({ carImage: file });
    }
  };

  return (
    <div className="form">
      <div className="form_form_container">
        <p className="form_text form_form_title">Piloto y automóvil</p>
        <div className="form_form">
          <div className="three_rows">
            <Input value={form.main.name} label="Nombre" placeholder="Escribe tu nombre" onChanged={(val) => updateForm('main.name', val)} />
            <Input
              value={form.main.phone}
              type="number"
              regex="^\d{8,9}$"
              label="Celular"
              placeholder="099 123 456"
              onChanged={(val) => updateForm('main.phone', val)} />
            <Input value={form.main.car} label="Auto" placeholder="Volkswagen Vento GLI" onChanged={(val) => updateForm('main.car', val)} />
          </div>
          <Input value={form.main.address} label="Dirección" placeholder="Escribe tu dirección" onChanged={(val) => updateForm('main.address', val)} />
          <Input
            type="email"
            regex={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
            value={form.main.email}
            label="Correo electrónico"
            placeholder="Escribe tu correo electrónico"
            onChanged={(val) => updateForm('main.email', val)} />
          <Input value={form.main.emergencyContact} label="Contacto de emergencia" placeholder="Nombre del contacto de emergencia" onChanged={(val) => updateForm('main.emergencyContact', val)} />
          <Input
              value={form.main.emergencyContactPhone}
              type="number"
              regex="^\d{8,9}$"
              label="Celular del contacto de emergencia"
              placeholder="099 123 456"
              onChanged={(val) => updateForm('main.emergencyContactPhone', val)} />
        </div>
      </div>

      {getStorageData().pilotsNumber === '2' && <div className="form_form_container">
        <p className="form_text form_form_title">Segundo piloto</p>
        <div className="form_form">
          <Input value={form.secondary.name} label="Nombre" placeholder="Escribe tu nombre" onChanged={(val) => updateForm('secondary.name', val)} />
          <Input
            value={form.secondary.phone}
            type="number"
            regex="^\d{8,9}$"
            label="Celular"
            placeholder="099 123 456"
            onChanged={(val) => updateForm('secondary.phone', val)} />
          <Input value={form.secondary.address} label="Dirección" placeholder="Escribe tu dirección" onChanged={(val) => updateForm('secondary.address', val)} />
          <Input
            type="email"
            regex={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
            value={form.secondary.email}
            label="Correo electrónico"
            placeholder="Escribe tu correo electrónico"
            onChanged={(val) => updateForm('secondary.email', val)} />
          <Input value={form.secondary.emergencyContact} label="Contacto de emergencia" placeholder="Nombre del contacto de emergencia" onChanged={(val) => updateForm('secondary.emergencyContact', val)} />
          <Input
              value={form.secondary.emergencyContactPhone}
              type="number"
              regex="^\d{8,9}$"
              label="Celular del contacto de emergencia"
              placeholder="099 123 456"
              onChanged={(val) => updateForm('secondary.emergencyContactPhone', val)} />
        </div>
      </div>}

      <div className="form_form_container">
        <p className="form_text form_form_title">Foto del automóvil</p>
        <div className="form_form">
          <FileInput accept={IMG_TYPES.join(', ')} icon="img" label="Sube una foto del automóvil" onChanged={loadFile} />
        </div>
      </div>
    </div>
  );
}

function getFormDataFromStorage() {
  const { storage, isSinglePilot, keys } = getFormInfo();
  let data = {};

  if (isSinglePilot) {
    updateStorage({ secondary: undefined });
  }

  keys.forEach((k) => {
    const [group, key] = k.split('.');
    data = {
      ...data,
      [group]: { ...data[group], [key]: (storage[group] ?? {})[key] ?? '' },
    };
  });

  return data;
}

function isFormValid() {
  const { storage, keys } = getFormInfo();
  let valid = true;

  keys.every((k) => {
    const [group, key] = k.split('.');

    if (!!(storage[group] ?? {})[key]) {
      return true;
    }

    valid = false;
    return false;
  });

  return valid;
}

function getFormInfo() {
  const storage = getStorageData();
  const isSinglePilot = storage.pilotsNumber === '1';
  const keys = isSinglePilot ? MAIN_FORM_KEYS : [...MAIN_FORM_KEYS, ...SECONDARY_FORM_KEYS];
  return { storage, isSinglePilot, keys };
}

export default Details;
