import { useEffect, useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import './Input.css';

function FileInput({ icon = 'pdf', accept = '', label = 'Cargar archivo', name = '', onChanged = () => '' }) {
  const [fileName, setFileName] = useState(name);
  const fileInputRef = useRef(null);

  useEffect(() => setFileName(name), [name, setFileName]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file || (accept && !accept.includes(file?.type.split('/')[1]))) {
      return;
    }

    setFileName(file ? file.name : '');
    onChanged(file);
  };

  return (
    <div className="input">
      <button className="input_input input_file" onClick={() => fileInputRef.current.click()}>
        <Icon icon={icon} color="#FFFFFF" />
        <span className="file_name">{fileName ? fileName : label}</span>
      </button>
      <input type="file" accept={accept} ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
    </div>
  );
}

export default FileInput;
