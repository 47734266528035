import { useEffect, useState } from 'react';
import './Toast.css';

function Toast({ message = '', error = false, onClose = () => '' }) {
  const [msg, setMsg] = useState('');

  useEffect(() => setMsg(message), [message, setMsg]);

  return (
    <div className="toast_container">
      <div className={error ? 'toast error' : 'toast'} onClick={() => onClose()}>{msg}</div>
    </div>
  );
}

export default Toast;
