const STORAGE_KEY = 'form_data';

function updateStorage(values) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...getStorageData(), ...values }));
}

function getStorageData() {
  return JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
}

function clearStorage() {
  localStorage.removeItem(STORAGE_KEY);
}

export {
  updateStorage,
  getStorageData,
  clearStorage,
};
