import { useNavigate } from 'react-router-dom';
import './Header.css';

function Header() {
  const navigate = useNavigate();

  return (
    <header className="header">
      <div className="center">
        <div className="logo">
          <img onClick={() => navigate('')} src="/logos/logo.png" alt="DriveFast" />
        </div>
      </div>
    </header>
  );
}

export default Header;