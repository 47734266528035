import './ComingSoon.css';

function ComingSoon() {
  return (
  <div className="coming">
    <div className="content">
      <h1>Próximamente...</h1>
      <div className="logos">
        <img src="/logos/drivefast.png" alt="DriveFast" />
        <img src="/logos/auvo.png" alt="Auvo" />
      </div>
    </div>
  </div>
  );
}

export default ComingSoon;