import { useEffect, useState } from 'react';
import { getStorageData, updateStorage } from '../../../../Common/StorageManager';
import Checkbox from '../../../../Components/Input/Checkbox';
import './Terms.css';

function Terms({ disableNext = () => true }) {
  const [accepted, setAccepted] = useState(getStorageData().acceptedTerms ?? false);

  useEffect(() => disableNext(!accepted), [disableNext, accepted]);

  const acceptedChanged = (acceptedTerms) => {
    setAccepted(acceptedTerms);
    updateStorage({ acceptedTerms })
  };

  return (
    <div className="terms">
      <p className="main_title terms_title">Términos y condiciones</p>
      <p className="terms_disclaimer">LA CONDUCCIÓN EN UN AUTÓDROMO ES PELIGROSA Y PUEDE IMPLICAR LESIONES O INCLUSO LA MUERTE PARA PARTICIPAR EN ESTE EVENTO USTED DEBE LEER Y ACEPTAR LA SIGUIENTE DECLARACIÓN</p>
      <p className="term">* Estoy familiarizado con la naturaleza del evento de automovilismo deportivo al cual me estoy registrando y soy consciente de todos los riegos inherentes a esta actividad</p>
      <p className="term">* Soy suficientemente competente para operar y controlar el vehículo con el cual estaré participando y mi nivel de competencia es apropiado al nivel declarado anteriormente</p>
      <p className="term">* La organización se reserva el derecho de determinar el trazado del circuito a utilizar el cual sera aceptado sin condición</p>
      <p className="term">* Estoy en conocimiento que otros participates con variados niveles de experiencia van a utilizar el mismo circuito en el mismo momento</p>
      <p className="term">* Estoy en conocimiento que el objetivo del evento no es realizar entrenamientos y que los pilotos expertos designados por la organización para asistir a los participantes (en caso de existir) permitirán conocer el trazado del circuito y garantizar la seguridad a todos los participantes</p>
      <p className="term">* Estoy en buena forma y mi nivel de vista esta al menos en el mínimo nivel requerido para una licencia de conducir otorgada por las autoridades nacionales</p>
      <p className="term">* No sufro de ningún impedimento físico o mental que pueda afectar de forma adversa mi competencia para operar y controlar el vehículo utilizado en esta actividad y resultar inseguro para mí y para otros participates del evento.</p>
      <p className="term">* No consumiré o estaré bajo los efectos del consumo del alcohol o drogas (incluidas drogas prescritas que pueden afectar de forma adversa a la seguridad) en ningún momento mientras este participando de la actividad</p>
      <p className="term">* La organización se reserva el derecho de inspeccionar el vehículo utilizado en el evento en cualquier momento sin ellos implicar ninguna responsabilidad para la misma. Los participantes son los únicos responsables del funcionamiento correcto v seguro de los vehículos utilizados en el evento</p>
      <p className="term">* Acepto portar en todo momento y en forma visible una pulsera identificatoria provista por la organización que indica haber concurrido a la sesión de instrucciones de seguridad (briefing) y:</p>

      <ul className="term_list">
        <li>haber concurrido a la sesión de instrucciones completa;</li>
        <li>haber escuchado el contenido completo de las instrucciones;</li>
        <li>haber comprendido cabalmente las instrucciones;</li>
        <li>aceptar las instrucciones sin excepción</li>
      </ul>

      <p className="term">* Sin perjuicio de lo anterior, acepto que la organización se reserve el derecho a excluirme del evento sin previo aviso y en cualquier momento ante la constatación o sospecha por parte de la misma de que el participante haya incurrido en riesgos inapropiados (a sola discreción de la organización), daños propios o a otros participantes</p>
      <p className="term">* No realizare ningún reclamo hacia la organización, sus integrates o personal contratado por la misma en caso de lesiones, daños propios o a terceros durante el evento incluyendo la muerte</p>
      <p className="term">Declaro expresamente que serán de mi cargo y cuenta exclusivas cualquier daño y/o perjuicio que pueda ocasionar durante mi participación en este evento y/o con ocasión del mismo, sea a la Asociación Uruguaya de Volantes, a su personal o colaboradores, a cualquier otra entidad organizadora o co-organizadora, a otros participantes y/o a terceros, incluyendo tanto daños de naturaleza patrimonial como extrapatrimonial, sin excepción alguna, los que asumo íntegramente y me obligo a resarcir. Por otra parte, exonero expresamente a la Asociación Uruguaya de Volantes, a sus Directivos, personal o colaboradores, al igual que a cualquier otra entidad organizadora o co-organizadora de cualquier daño y/o perjuicio, patrimonial o extrapatrimonial, que pueda sufrir el que suscribe (y en su caso sus causahabientes a título particular o universal) con causa y/o con ocasión de este evento, incluyendo por negligencia o culpa grave con la sola exclusión del dolo que les sea imputable.</p>

      <Checkbox label="He leído y acepto los términos y condiciones" value={accepted} onChanged={acceptedChanged} />
    </div>
  );
}

export default Terms;