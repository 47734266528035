import Wizard from '../../Components/Wizard/Wizard';
import Welcome from './Steps/Welcome/Welcome';
import Details from './Steps/Details/Details';
import Terms from './Steps/Terms/Terms';
import Payment from './Steps/Payment/Payment';

const initialSteps = [
  { label: 'Bienvenida', component: Welcome },
  { label: 'Piloto y automóvil', component: Details },
  { label: 'Términos y condiciones', component: Terms },
  { label: 'Pago', component: Payment },
];

function FormPage() {
  return (<Wizard stepList={initialSteps} />);
}

export default FormPage;
