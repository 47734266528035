import { useEffect, useState } from 'react';
import { updateStorage, getStorageData } from '../../../../Common/StorageManager';
import SelectInput from '../../../../Components/Input/SelectInput';
import './Welcome.css';
import '../Shared.css';

const disciplines = {
  drift: 'drift',
  trackday: 'trackday',
};

const hasParticipatedOptions = [
  { option: true, label: 'Ya he participado de un track day' },
  { option: false, label: 'Soy nuevo en los eventos de track day' },
];

const isAuvoPartner = [
  { option: true, label: 'Si' },
  { option: false, label: 'No' },
];

const discipline = [
  { option: disciplines.drift, label: 'Drift' },
  { option: disciplines.trackday, label: 'Trackday' },
];

const pilotsNumber = [
  { option: 1, label: '1' },
  { option: 2, label: '2' },
];

const pricingMap = {
  partner: {
    [disciplines.trackday]: {
      1: 4800,
      2: 8600,
    },
    [disciplines.drift]: {
      1: 4300,
      2: 4300,
    },
  },
  common: {
    [disciplines.trackday]: {
      1: 6800,
      2: 11600,
    },
    [disciplines.drift]: {
      1: 5300,
      2: 5300,
    },
  },
};

function Welcome({ disableNext = () => true }) {
  const [storage, setStorage] = useState(getStorageData() ?? {});

  useEffect(() => {
    updateStorage(storage);
    disableNext(!storage.hasParticipated || !storage.isAuvoPartner || !storage.discipline || !storage.pilotsNumber);
  }, [storage, disableNext]);

  const updateFields = (field, value) => {
    const store = { ...storage, [field]: value };
    const price = getPrice(store);
    setStorage({ ...store, price });
  };

  return (
    <div className="form">
      <p className="form_text">¡Bienvenido al portal de inscripción de DriveFast para track days en el Autódromo del Pinar! Aquí puedes registrarte fácilmente para emocionantes sesiones de conducción en pista. Solo tienes que seguir unos simples pasos para unirte a la experiencia.</p>
      <div className="form_form_container">
        <p className="form_text form_form_title">Tipo de sesión</p>
        <div className="form_form">
          <SelectInput label="Ya has participado?" value={storage?.hasParticipated ?? ''} options={hasParticipatedOptions} onChanged={(value) => updateFields('hasParticipated', value)} />
          <SelectInput label="Eres socio de AUVO?" value={storage?.isAuvoPartner ?? ''} options={isAuvoPartner} onChanged={(value) => updateFields('isAuvoPartner', value)} />
          <SelectInput label="Disciplina" value={storage?.discipline ?? ''} options={discipline} onChanged={(value) => updateFields('discipline', value)} />
          <SelectInput label="Cantidad de pilotos" value={storage?.pilotsNumber ?? ''} options={pilotsNumber} onChanged={(value) => updateFields('pilotsNumber', value)} />
        </div>
        {!!storage.price && <span className="form_form_price">Precio: ${storage.price}</span>}
      </div>
    </div>
  );
}

function getPrice(store) {
  const { isAuvoPartner, discipline, pilotsNumber } = store;

  if (!isAuvoPartner || !discipline || !pilotsNumber) {
    return 0;
  }

  const map = pricingMap[JSON.parse(isAuvoPartner) ? 'partner' : 'common'];
  return map[discipline][pilotsNumber];
}

export default Welcome;
