import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getStorageData, clearStorage } from '../../Common/StorageManager';
import { saveCodeData } from '../../Common/CodeManager';
import Loader from '../Loader/Loader';
import Icon from '../Icon/Icon';
import Toast from '../Toast/Toast';
import './Wizard.css';

const stepStatus = {
  active: 'active',
  pending: 'pending',
  completed: 'completed',
};

const finishLabelInitial = 'Enviar';

function Wizard({ stepList }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [files, setFiles] = useState({});
  const [disableNext, setDisableNext] = useState(false);
  const [sending, setSending] = useState(false);
  const [steps, setSteps] = useState([]);
  const [toast, setToast] = useState('');
  const [finishLabel, setFinishLabel] = useState(finishLabelInitial);
  const navigate = useNavigate();

  useEffect(() => {
    const stepsWithDefaultStatus = stepList.map(el => ({ ...el, status: stepStatus.pending }));
    const stepsWithFinalStatus = handleSteps(currentStep, stepsWithDefaultStatus);
    setSteps(stepsWithFinalStatus);
  }, [currentStep, stepList]);

  const onSubmit = async () => {
    const formData = new FormData();
    const { token, ...body } = getStorageData();
    createFormData(body, formData, files);
    sendRequest(formData, token);
  };  

  const sendRequest = (data, token) => {
    setFinishLabel('Enviando...');
    setSending(true);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API}/register/new`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'captcha': token,
      },
      data,
    }).then(({ data }) => {
      if (data.ok) {
        requestSuccess(data.code);
      }
    }).catch(({ response }) => {
      let error = 'Algo salió mal';

      if (response?.data?.message === 'email already registered') {
        error = 'Un corredor con este email ya está registrado';
      }

      setToast(error);
      setTimeout(() => setToast(''), 5000);
    }).finally(() => {
      setFinishLabel(finishLabelInitial);
      setSending(false);
    });
  }

  const requestSuccess = (code) => {
    saveCodeData(code, getStorageData().paymentMethod);
    setCurrentStep(0);
    setFiles({});
    clearStorage();
    navigate('/code');
  }


  const handleFiles = (file) => {
    setFiles((prevFiles) => {
      const key = Object.keys(file)[0];
      const newFiles = { ...prevFiles };

      if (file[key] === undefined) {
        delete newFiles[key];
      } else {
        newFiles[key] = file[key];
      }

      return newFiles;
    });
  }

  function createFormData(body, formData, files) {
    appendFormData(body, formData);

    for (const key in files) {
      if (Object.hasOwnProperty.call(files, key)) {
        formData.append(key, files[key]);
      }
    }
  }

  function appendFormData(data, formData, parentKey) {
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const nestedKey = parentKey ? `${parentKey}[${key}]` : key;
        if (typeof data[key] === 'object' && data[key] !== null) {
          appendFormData(data[key], formData, nestedKey);
        } else {
          formData.append(nestedKey, data[key]);
        }
      }
    }
  }

  function getStepComponent() {
    if (!steps.length || !steps[currentStep]?.component) {
      return <p style={{ color: '#FFFFFF' }}>No component found</p>;
    }

    const Component = steps[currentStep].component;
    return <Component
      disableNext={(value) => setDisableNext(value ?? false)}
      filesChanged={handleFiles}
    />;
  }

  return (
    <>
      <div className="wizard">
        <div className="steps">
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <div className="step_element">
                <div className={`step_element_number step_element_number_${step.status}`}>{getStepElement(step, index)}</div>
                <p className="step_element_label">{step.label}</p>
              </div>
              {index < steps.length - 1 && <div className="step_separator"><hr /></div>}
            </React.Fragment>
          ))}
        </div>

        <div className="step_title">
          <div className="step_element_number step_element_number_active">{currentStep + 1}</div>
          <p className="step_element_label">{steps[currentStep]?.label}</p>
        </div>

        <div className="output">{getStepComponent(steps, currentStep)}</div>

        <div className="actions" style={{ justifyContent: currentStep === 0 ? 'flex-end' : 'space-between' }}>
          {currentStep > 0 && <button className="actions_button actions_back" onClick={() => setCurrentStep(currentStep - 1)}>Volver</button>}
          {
            currentStep < steps.length - 1 ?
              <button
                className="actions_button"
                style={getDisabledButtonConfig(disableNext)}
                onClick={() => setCurrentStep(currentStep + 1)}
                disabled={disableNext || sending}>
                Continuar
              </button> :
              <button
                className="actions_button"
                style={getDisabledButtonConfig(disableNext || sending)}
                onClick={() => onSubmit()}
                disabled={disableNext || sending}>
                {finishLabel}
              </button>
          }
        </div>
      </div>
      {toast && <Toast message={toast} error={true} onClose={() => setToast('')} />}
      {sending && <Loader message="Enviando..!" />}
    </>
  );
}

function getStepElement(step, index) {
  return step.status === stepStatus.completed ? <Icon size="14" icon="check" color="#FFFFFF" /> : index + 1;
}

function handleSteps(index = 0, steps = []) {
  if (index > steps.length - 1 || index < 0) {
    return steps;
  }

  return steps.map((step, i) => {
    step.status = index > 0 && index > i ? stepStatus.completed : stepStatus.pending;
    step.status = i === index ? stepStatus.active : step.status;
    return step;
  });
}

function getDisabledButtonConfig(disabled) {
  return {
    background: disabled ? '#696969' : '#FFFFFF',
    cursor: disabled ? 'initial' : 'pointer',
  };
}

export default Wizard;
