import './Icon.css';

function Icon({ size = 16, color = 'grey', icon = 'check' }) {
  return (
    <div className="icon" style={{
      width: `${size / 16}rem`,
      height: `${size / 16}rem`,
      background: color,
      WebkitMaskImage: `url('/Icons/${icon}.svg')`,
    }}></div>
  );
}

export default Icon;
