export const IMG_TYPES = ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.tiff', '.webp'];

export const PDF_TYPES = ['.pdf'];

export const IMG_PDF_TYPES = [...IMG_TYPES, ...PDF_TYPES];

export const paymentMethod = {
  whatsapp: 'whatsapp',
  voucher: 'voucher',
};
