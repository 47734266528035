import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import './Checkbox.css';

function Checkbox({ customStyles = {}, label, value = false, onChanged = () => '' }) {
  const [val, setVal] = useState(value);

  const triggerChange = (value) => {
    setVal(value);
    onChanged(value);
  }

  return (
    <div style={customStyles} onClick={() => triggerChange(!val)} className="checkbox_container">
      <div className="checkbox">{val && <Icon icon="check" color="#FFFFFF" size="14" />}</div>
      {label && <p className="checkbox_label">{label}</p>}
    </div>
  );
}

export default Checkbox;
