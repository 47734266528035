const CODE_KEY = 'code_data';

function saveCodeData(code, paymentMethod) {
  localStorage.setItem(CODE_KEY, JSON.stringify({ code, paymentMethod }));
}

function getCodeData() {
  return JSON.parse(localStorage.getItem(CODE_KEY));
}

function clearCodeData() {
  localStorage.removeItem(CODE_KEY);
}

export {
  saveCodeData,
  getCodeData,
  clearCodeData,
};
