import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { getStorageData, updateStorage } from '../../../../Common/StorageManager';
import { IMG_PDF_TYPES, paymentMethod } from '../../../../Common/Constants';
import Toast from '../../../../Components/Toast/Toast';
import SelectInput from '../../../../Components/Input/SelectInput';
import FileInput from '../../../../Components/Input/FileInput';
import '../Shared.css';
import './Payment.css';

const accountNumber = '001204021615';

const paymentMethods = [
  { option: paymentMethod.whatsapp, label: 'Enviar por Whatsapp' },
  { option: paymentMethod.voucher, label: 'Subir el comprobante' },
];

function Payment({ disableNext = () => true, filesChanged = () => '' }) {
  const [file, setFile] = useState({});
  const [storage, setStorage] = useState(getStorageData());
  const [toast, setToast] = useState('');
  const [captcha, setCaptcha] = useState('');

  useEffect(() => {
    updateStorage(storage);
    disableNext(invalidForm(storage, file, captcha));
  }, [storage, file, disableNext, captcha]);

  const copyClipboard = async () => {
    await navigator.clipboard.writeText(accountNumber);
    setToast('Número de cuenta copiado!');
    setTimeout(() => setToast(''), 5000);
  };

  const updateFields = (key, value) => {
    setStorage({ ...storage, [key]: value });
    setFile({});
    filesChanged({ voucher: undefined });
  }

  const loadFile = (file) => {
    if (file) {
      setFile({ voucher: file });
      filesChanged({ voucher: file });
    }
  };

  const onCaptchaChange = (token) => {
    setCaptcha(token);
    setStorage({ ...storage, token });
  }

  return (
    <div className="payment">
      <p className="main_title">Detalles de transferencia:</p>
      <div className="payment_details">
        <p>Banco Santander</p>
        <p>Sucursal 12</p>
        <p>Cuenta número: <span onClick={copyClipboard}>{accountNumber}</span></p>
        <p>A nombre de AUVO</p>
        <p>Concepto: NOMBRE Y APELLIDO</p>
      </div>
      <p className="main_title">Total: ${storage.price}</p>
      <hr className="payment_separator" />
      <div className="payment_method">
        <SelectInput label="Elige como enviar tu comprobante" value={storage?.paymentMethod ?? ''} options={paymentMethods} onChanged={(value) => updateFields('paymentMethod', value)} />
        {storage.paymentMethod === paymentMethod.voucher && <FileInput accept={IMG_PDF_TYPES.join(', ')} icon="img" label="Subir el comprobante" onChanged={loadFile} />}
      </div>
      <div className="captcha">
        <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA} onChange={onCaptchaChange} />
      </div>
      {toast && <Toast message={toast} onClose={() => setToast('')} />}
    </div>
  );
}

function invalidForm(storage, files, captcha) {
  if (!captcha) {
    return true;
  }

  if (storage.paymentMethod === paymentMethod.voucher) {
    return !files.voucher;
  }

  return !storage.paymentMethod;
}

export default Payment;
