import './Loader.css';

function Loader({ message = 'Cargando...' }) {
  return (
    <div className="loader">
      <div className="content">
        <p className="message">{message}</p>
        <span className="bar"></span>
      </div>
    </div>
  );
}

export default Loader;
