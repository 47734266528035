import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCodeData, clearCodeData } from '../../Common/CodeManager';
import { paymentMethod } from '../../Common/Constants';
import Toast from '../../Components/Toast/Toast';
import Icon from '../../Components/Icon/Icon';
import './Code.css';

const CONTACT_MAIL = 'auvo@adinet.com.uy';

function Code() {
  const [codeData, setCodeData] = useState('');
  const [toast, setToast] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const codeData = getCodeData();

    if (!codeData || !codeData?.code) {
      navigate('/');
      return;
    }

    setCodeData(codeData);
    
    if (codeData.paymentMethod === paymentMethod.whatsapp) {
      setMsg(`Hola! Me gustaría enviar mi comprobante de pago para con el código de reserva: ${codeData.code}`);
    }
  }, [navigate]);

  const copyClipboard = async () => {
    await navigator.clipboard.writeText(codeData.code);
    setToast('Código de reserva copiado!');
    setTimeout(() => setToast(''), 5000);
  };

  const goForm = () => {
    navigate('/form');
    clearCodeData();
  }

  return (
    <>
      <div className="code">
        <h1 className="title">¡Todo listo!</h1>
        <p className="text">Gracias por registrarte para nuestro evento de Track Day. Hemos recibido tu información y estamos emocionados de tenerte a bordo!</p>
        <p className="text">Nos pondremos en contacto contigo pronto para proporcionarte los detalles y los siguientes pasos a seguir. Si tienes alguna pregunta o necesitas información adicional, no dudes en contactarnos en <a href={`mailto:${CONTACT_MAIL}`}>{CONTACT_MAIL}</a> </p>
        <div className="reservation">
          <p className="code_title">Código de reserva</p>
          <p className="code_text" title="Click para copiar" onClick={copyClipboard}>{codeData.code}</p>
        </div>

        {codeData.paymentMethod === paymentMethod.whatsapp &&
          <a href={`https://wa.me/59898183927?text=${encodeURI(msg)}`} target="_blank" rel="noreferrer" className="send_whatsapp">
            <Icon icon="whatsapp" color="#FFFFFF" size="24" />
            <p>Enviar comprobante por WhatsApp</p>
          </a>
        }

        <button className="action" style={{ marginTop: codeData.paymentMethod === paymentMethod.whatsapp ? '2rem' : 0 }} onClick={() => goForm()}>Registrar otro piloto</button>
      </div>
      {toast && <Toast message={toast} onClose={() => setToast('')} />}
    </>
  );
}

export default Code;
