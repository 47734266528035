import { useEffect, useState } from 'react';
import './Input.css';

function Input({ customStyles = {}, type = 'text', placeholder, label, value, onChanged = () => '', regex }) {
  const [val, setVal] = useState(value);
  const [fieldValid, setFieldValid] = useState(true);

  useEffect(() => {
    if (!!regex && !!value) {
      setVal(value);
      setFieldValid(new RegExp(regex).test(value));
    }
  }, [value, type, regex]);

  const triggerChange = ({ target }) => {
    setVal(target.value);
    onChanged(target.value);
  }

  const handleRegexValueChange = ({ target }) => {
    const value = target.value;
    const valid = new RegExp(regex).test(value);
    setVal(value);
    onChanged(valid ? value : '');
    setFieldValid(valid);
  };

  return (
    <div className="input" style={customStyles}>
      {label && <p className="input_label">{label}</p>}
      {!regex && <input className="input_input" type={type} value={val} placeholder={placeholder} onChange={triggerChange} />}
      {regex && <input className={fieldValid ? 'input_input' : 'input_input invalid_input'} type={type} value={val} placeholder={placeholder} onChange={handleRegexValueChange} />}
    </div>
  );
}

export default Input;
