import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import './Input.css';

function SelectInput({ customStyles = {}, label, placeholder = 'Selecciona una opción', value, options = [], onChanged = () => '' }) {
  const [option, setOption] = useState(value);

  const triggerChange = (value) => {
    onChanged(value);
    setOption(value);
  }

  return (
    <div className="input" style={customStyles}>
      {label && <p className="input_label">{label}</p>}
      <div className="input_select_container">
        <select className="input_select" value={option} onChange={(el) => triggerChange(el.target.value)}>
          {placeholder && <option value="">{placeholder}</option>}
          {options.map((o, i) => <option key={i} value={o.option}>{o.label}</option>)}
        </select>
        <Icon className="input_select_icon" icon="down" color="#FFFFFF" size="10" />
      </div>
    </div>
  );
}

export default SelectInput;
