import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import Header from './Components/Header/Header';
import FormPage from './Pages/Form/Form';
import ComingSoon from './Pages/ComingSoon/ComingSoon';
import Code from './Pages/Code/Code';
import './App.css';

function App() {
  useEffect(() => {
    axios({ method: 'get', url: `${process.env.REACT_APP_API}/health/alive` })
    .then(({ data }) => console.log(data))
    .catch((error) => console.error(error));
  }, []);

  return (
    <div className="background">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="/form" element={<FormPage />} />
          <Route path="/code" element={<Code />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
